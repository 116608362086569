/* NAVIGATION */

@mixin navigation {

	.link-up {
		display: none;
		text-decoration: none!important;
		line-height: 1em;

		> .back-arr {
			font-size: 30px!important;
			line-height: 0.5em;
		}

		> .back-title {
			font-weight: 800;
			font-size: 16px;
			margin-bottom: 10px;
			line-height: 1.2em;
			display: block;
		}
	}

	.top-arr {
		text-decoration: none;
		display: block;
		float: right;
		margin-top: 6px;
		display: none;

		@include media-query(palm) {
			display: block;
		}
		
		> span {
			font-size: 40px!important;
		}
	}

	#navigation1 {

		> .language_chooser {
			
			display: none;

			@include media-query(palm) {
				display: block;
			}

			> a {
				margin-bottom: 0;
			}
		}
	}

	#navigation2 {
		max-width: 670px;
		width: 100%;

		> .language_chooser {

			display: none;

			@include media-query(palm) {
				display: block;
			}
			
			> a {
				text-decoration: none;
				margin-bottom: 0;
			}
		}
	}

	@include media-query(palm) {
		
		#navigation1 {
			position: relative;
			display: none;
			text-align: left;		

			> .language_chooser {
				display: block;

				> a {
					font-size: 14px;
				}
			}

			a {
				padding: 0;
				margin: 0 0 0.4em 0;
				display: block;
				font-size: 16px;

				&:hover, &:active {
					background: none!important;
				}
			}

			+ .thick {
				height: 1px;

				.level-0 & {
					height: 2px;
				}
			}

			.level-0 & {
				display: block;
			}
				
		}
	
		#navigation2 {
			position: relative;
			margin-top: 0;
			padding-bottom: 5px;
			border-bottom: 2px solid black;
			width: inherit;

			> .language_chooser {
				display: block;
			}
			
			a {
				display: block;
				padding: 0;
				margin: 0 0 0.6em 0;
				font-size: 14px;

				&:hover, &:active {
					background: none;
				}
			}

			.link-up {
				display: block;
				margin-top: 2px;

				.level-0 & {
					display: none;
				}
			}

			.level-0 & {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}

}

/* END NAVIGATION */
