@import "partials/interface";
//@import "sprites/*.png";
//@include all-sprites-sprites(true);

@mixin html {
	
	html {
		height: 100%;
		background: #fffdeb;
	}

	.title-txt {
		margin-left: 0;
		margin-top: 0!important;
		overflow: auto;

		a {
			text-align: left!important;
			margin-left: 0!important;
		}

		@include media-query(lap) {
			margin-top: -35px;
			padding: 0;
			margin-left: 0;
			margin-right: 20%;
			margin-bottom: 0;
		}

		@include media-query(portrait) {

			text-align: left!important;
			margin-left: 0;

			a {
				text-align: left!important;
				margin-left: 0!important;
			}
		}

		> p {
			margin: 0;
		}
	}

	iframe, #contentkatalog {
		width: 100%!important;
	}

	table.userdata {	
		margin-left: 55px;

		@include media-query(palm) {
			margin-left: 0;
			
			width: 100%;
			padding: 0;	

			input, textarea {
				width: 100%;
				padding: 0;
			}
		}
	}

}



@mixin body {

	body {
		height: 100%;		
		transition: opacity 0.3s;
		opacity: 1!important;

		> .container {
			max-width: 1000px;
			margin-bottom: 0;
			width: inherit;

			> .language_chooser {
				margin-top: 28px;

				@include media-query(palm) {
					display: none;
				}
			}

			@include responsive-padding-horizontal($base-spacing-unit, palm, $base-spacing-unit/1.5);
			
			@include media-query(lap) {
				margin-top: 16px;
			}

			@include media-query(palm) {
				margin-top: $base-spacing-unit/2;
			}
			
		}

	}
	
}



@mixin header {

	#logo {

		@include media-query(palm) {
			margin: 20px auto 0;
			width: 75%;

			> a {
				display: block;
				
				> img {
					width: 100%;
					height: auto;
				}

			}

			+ hr {
				display: none;

				.level-0 & {
					display: block;
				}

			}
		}

	}



}

@mixin content {

	#content {
		width: 70%;

		@include media-query(palm) {
			float: none;
			width: 100%;
		}

		> * {
			box-sizing: border-box;
			padding-right: $base-spacing-unit;

			@include media-query(palm) {
				padding-right: 0;
			}

		}

	}

	#copy {

		@include media-query(palm) {
			margin-top: $base-spacing-unit;
		}

		h3 {
			font-weight: 800;
		}

		h2 {
			@include media-query(palm) {
				margin-top: $base-spacing-unit;
			}
		}

		img {
			float: none;
			display: block;
			clear: left;
		}

		hr {
			margin-top: $base-spacing-unit;
			margin-bottom: $base-spacing-unit;
		}

		 div {
			float: none;
    		clear: both;
		}

		> .teaser-grid:first-child {
			padding-top: 0;
		}
	}
	
}

@mixin columnimage {

	#columnimage {
		width: 30%;

		> img {
			width: 100%;
			height: auto;

			@include media-query(palm) {
				margin-bottom: 10px;
			}

		}
		
		@include media-query(palm) {
			float: none;
			width: 100%;
			border-bottom: none;
		}

	}

}


@mixin right-col {

	#right-col {
		width: 30%;
		text-align: left;

		// .title-txt {
		// 	line-height: 2.65em;
		// 	font-weight: bold;
		// }
		
		@include media-query(palm) {
			float: none;
			width: 100%;
			border-bottom: none;
		}

		> .right-col-text:first-child {
				padding-top: 2em!important;

		}

	}

	
	.right-col-text, .right-col-text-bottom  {
		border-bottom: 1px solid #cc0929;
		font-size: 1rem;
		padding-top: 1em;

		h1, h2,h3,h4,h5 {
			margin-bottom: 0.5em;
			font-weight: bold;
		}

		p {
			margin-top: 0;
			margin-bottom: 1em;
		}
	}

	.right-col-text-bottom {
		border-bottom: none;
	}

}


@mixin teaser-grid {

	.teaser-grid {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
		padding-top: 20px;
		direction: ltr;
	}

	.teaser-grid__item {
		width: 50%;
		font-size: 1rem;
		padding-right: 20px;

		&--third {
			width: 33.3%;

			@include media-query(palm) {
				width: 100%;
			}
		}

		&--padded-imgs {

			img {
				width: 100%;
				height: auto;
				padding-right: 15%;
			}

		}

		> .teaser-grid__item__image {
			padding-right: 15%;
			margin-bottom: 0.5em!important;
			display: block;
			
			> img {
				width: 100%;
				// box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.52);
			}
		}

		> p {
			margin-top: 0;
			margin-bottom: 1em;
		}

		.btn {
			text-decoration: none;
			text-align: left;
		}

		&--special-lots {

			> img {
				padding-left: 10%;

			}
		}
	}

	.teaser-grid__item__headline {
		min-height: 6em;
	}

}

@mixin static-catalogue {

	.static-catalogue {

		&__lotlist {
			text-align: left;
			height: 0;
			overflow: hidden;

			> h1 {
				float: none;
			}
		}

		&__lotdetail {
			text-align: left;
			height: 0;
			overflow: hidden;

			> img {
				display: block;
				width: 100%;
				margin-bottom: 1em;
			}

		}

	}

}

@mixin home-container {

	.home-container {
		display: flex;
		margin: 20px 0 0 0;

		a {
			text-decoration: underline;
		}

		#content, #right-col {
			float: none;

			@include media-query(palm) {
				width: 100%;
				// flex-shrink: 0;
			}
		}

		.title-txt {
			border-bottom: 1px solid #cc0929;
			padding-bottom: 0.5em;
			float: none;

			> h1 {
				font-weight: bold;
				line-height: 1.3;
			}

			@include media-query(lap) {
				margin-right: 0;
			}
		}

		@include media-query(lap) {
			margin-left: 0;
			margin-right: 0;
		}

		@include media-query(palm) {
			margin-top: 1em;
			margin-left: 0;
			margin-right: 0;
			flex-direction: column-reverse;
		}

	}

}

@mixin date-box {

	.dates {
		border-bottom: 1px solid #cc0929;
		padding-bottom: 2em;
		// margin-bottom: -1em;
	}

	.date-box {
		display: flex;
		align-items: center;
		font-size: 1rem;
		margin-top: 1em;

		> * {
			line-height: 1.2;

			&:first-child {
				padding-right: 0.5em;
			}
		}
	}

}

@mixin artist-index {

	.artist-index {
		text-align: left;
		padding-top: 20px;
		padding-bottom: 20px;

		> .artist-index__item {
			margin-bottom: 1em;

		}
		
	}

	.artist-index__pag {
		margin-top: 1em;
		margin-bottom: 1em;

		> a {
			text-decoration: none;
			padding-right: 5px;

		}

		> .active {
			text-decoration: underline;
			font-weight: bold;
			color: #cc0929;

		}
	}

}

@mixin footer {

	#footer {
		text-align: left;

		.left {
			float: none;
		}

		@include media-query(lap) {
			margin: 0;
		}
		
		@include media-query(portrait) {
			text-align: left;		
			display: block;

			.red {
				margin-bottom: $base-spacing-unit;
			}

			.break {
				display: block;
			}

			.right {
				// float: none;

				> a {
					line-height: 2em;
					margin-left: 0;
					margin-right: 1em;
				}
			}
		}
		
	}

}
