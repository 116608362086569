%svg-common {
	background: url("svg/sprite.css-a45a20ef.svg") no-repeat;
}

.svg-arrow-classic {
	@extend %svg-common;
	background-position: 100% 0;
	width: 27px;
	height: 20px;
}

.svg-auction {
	@extend %svg-common;
	background-position: 0 0;
	width: 37px;
	height: 37px;
}

.svg-instagram {
	@extend %svg-common;
	background-position: 0 100%;
	width: 24px;
	height: 24px;
}

