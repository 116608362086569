/**
*	AHB
*	gallery.css
* 	needs to be included explicitly in /css/_ahb.scss
*
**/

@import "vars";
@import "partials/utils";

#dia {
	display: none;
}

#diaarrows, #loading {
	display: none;
}

.heroic-gallery {
	display: block;
	position: relative;
	overflow: hidden;
	height: auto;
	margin: 20px 0 0 0;

	@include media-query(desk) {
		.touch & {
			margin-top: 20px;
		}
	}

	@include media-query(lap) {
		margin: 0 0 0 0;
	}

	@include media-query(portrait) {
		margin: 10px 0 0 0;
	}

	.is-immediate {
		//@include transition-duration(0s);
		-webkit-transition-duration: 0s!important;
		-moz-transition-duration: 0s!important;
		-o-transition-duration: 0s!important;
		transition-duration: 0s!important;
	}
	
}

.heroic-gallery__inner {
	overflow: hidden;
	width: 99.8%;
	margin-bottom: 50px;

}

.heroic-gallery__canvas {
	opacity: 0;
	width: 20000px; // initial width
	// max-height: 446px;
	margin-bottom: 64px; 

	&.is-visible {
		opacity: 1;
		margin-bottom: 0;
	}

	overflow: hidden;
}

.ctrl {
	opacity: 0;
	transition: opacity 0.3s;
	position: absolute;
	bottom: 0;
	cursor: pointer;
	z-index: 7999;
	
	.no-rgba & {
		background: #fff;
	}

	&.inactive {
		display: none;
	}

	&.heroic-gallery__prev {
		
		> span {
			// background: url(../images/pfeil_links.png);
		}

		right: 50px;
	}

	&.heroic-gallery__next {

		> span {
			transform: rotate(180deg);
			// background: url(../images/pfeil_rechts.png);
		}

		right: 0.2%;
	}

	span {
	
		display: block;
		// margin: 6px;
	}

	&.show {
		opacity: 1;
	}
}

.heroic-gallery__item {
	position: relative;
}

.heroic-gallery__item, .heroic-gallery__caption__item {
	transition: opacity 0.8s;
	opacity: 0;

	visibility: hidden;
	margin-bottom: -1px;
	min-height: 1em;
	// max-height: 446px;
	overflow: hidden;
	
	.fade & {
		visibility: visible;
		position: absolute;
		left: 0;
		float: none!important;
	}

	.slide & {
		opacity: 1;
		visibility: visible;
	}

	&.fadeIn {
		opacity: 1;
		z-index: 200;
	}
	
	a:hover {
		color: #000;
		text-decoration: underline;
	}

	&:hover {
		color: #000;
	}

}

.heroic-gallery__caption {
	transform: translate3d(0,0,0)!important;
	position: absolute;
	width: 50%;
	top: 0;
	bottom: 0;

	.no-csstransforms & {
		top: 0;
	}

	> .heroic-gallery__caption__item {
		display: block;
		background: url(../images/overlay_back.png);

		.rgba & {
			background: rgba(255,255,255, 0.8);
		}
	
		transition: all 0.8s ease;
		transform: translate3d(65%,0,0);
		opacity: 0;

		@include media-query(palm) {
			transform: translate3d(50%,0,0);
		}

		@include media-query(lap) {
			transform: translate3d(65%,0,0);
		}

		position: absolute;
		top: 0;
		bottom: 0;

		.heroic-gallery--thumbnails--static-text & {
			transform: translate3d(0,0,0);
			opacity: 1;
		}

		&.fadeIn, &.active {
			opacity: 1;
		}
		
		> div {
			position: absolute;
			bottom: 0;
			width: 35%;
			padding: 20px;
			text-align: left;
			font-size: 18px;

			@include media-query(palm) {
				width: 50%;
			}
	
			@include media-query(lap) {
				// width: 50%;
				font-size: 1.757vw;
			}

			> .btn {
				display: block;
				width: 75%;
				margin-top: 1em;
				border-color: #000;
				background-color: #000;

				@include media-query(palm) {
					width: 100%;
				}

				@include media-query(lap) {
					width: 75%;
				}
			}

			.vh-prices {
				margin-top: 1em;
			}

			label {
				font-size: inherit;
			}

			@include media-query(palm) {
				font-size: 10px;
				padding: 10px;
			}

			b {
				font-family: Helvetica, Arial;
				font-size: 18px;
				text-align: left;
				
				@include media-query(palm) {
					font-size: 10px;
					
				}
			}
	
			.no-rgba & {
				background: #fff;
			}
	
			&:empty {
				padding: 0;
				display: none;
			}
		}
	}

	&.captions--show > .heroic-gallery__caption__item {
		transform: translate3d(65%,0,0);

		@include media-query(palm) {
			transform: translate3d(50%,0,0);
		}

		@include media-query(lap) {
			// transform: translate3d(50%,0,0);
		}
		
	}

	.heroic-gallery--thumbnails--static-text & {
		position: relative;
		overflow: hidden;
	}

}

.heroic-gallery__image {

	background-size: cover;
	background-position: center;

	&:before {
		content: "";
		padding-top: 70.108%;
		display: block;
	}


	img {
		width: 100% !important;
		height: auto;
		display: block;
	}
}

.heroic-gallery__nav {
	display: none!important;
	overflow: visible;
	width: 100%;

	.is-visible + & {
		display: block;
	}

	.ctrl {
		display: none;
	}
}

.heroic-gallery__nav__inner {
	overflow: hidden;
	width: 99.8%;
}

.heroic-gallery__nav__item {
	opacity: 0.5;
	transform: translate3d(0,0,0);
	transition: opacity 0.8s ease;

	display: inline-block;
	padding-top: 6px;
	overflow: hidden;
	cursor: pointer;
	
	&.active {
		opacity: 1;
	}

	img {
		width: 100%;
		height: auto;

		margin-left: 6px;
	}

	&:first-child {
		img {
			margin-left: 0;
		}
	}
}

.heroic-gallery--simple {
	.ctrl {
		top: 45%;
	}

	.heroic-gallery__nav {
		display: none;
	}
}

