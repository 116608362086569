@mixin responsive-padding-horizontal($margin-default, $media-query, $margin-altered) {
	padding-left: $margin-default;
	padding-right: $margin-default;
	@include media-query($media-query){ 
			padding-left: $margin-altered;
			padding-right: $margin-altered;
	}
}

@mixin media-query($media-query){

    @if $media-query == palm{

        @media only screen and (max-width:$palm-end) { @content; }

    }

	@if $media-query == lap{

        @media only screen and (min-width:$desk-start + px) and (max-width:$lap-end + px) { @content; }

    }

    @if $media-query == desk{

        @media only screen and (min-width:$desk-start) { @content; }

    }

	@if $media-query == portrait{

        @media only screen and (orientation:portrait) { @content; }

    }

}

@mixin display() {

    .display--block {
        display: block!important;
    }

}

